<template>
    <Layout>
        <b-card
            header-class="bg-transparent border-primary"
            class="card border border-primary"
        >
            <h1>Proceed To Pay</h1>
            <p class="price">AED {{ test }}</p>
            <p class="warning_text">
                Please do not reload or refresh this page
            </p>
            <form
                method="POST"
                action="https://checkout.PayFort.com/FortAPI/paymentPage"
            >
                <input
                    class="form-control"
                    type="hidden"
                    v-model="paymentInfo.command"
                    name="command"
                />
                <input
                    type="hidden"
                    class="form-control"
                    v-model="access_code"
                    name="access_code"
                />
                <input
                    type="hidden"
                    class="form-control"
                    v-model="merchant_identifier"
                    name="merchant_identifier"
                />
                <input
                    type="hidden"
                    class="form-control"
                    v-model="merchant_reference"
                    name="merchant_reference"
                />
                <input
                    type="hidden"
                    class="form-control"
                    v-model="paymentInfo.amount"
                    name="amount"
                />
                <input
                    type="hidden"
                    class="form-control"
                    v-model="paymentInfo.currency"
                    name="currency"
                />
                <input
                    type="hidden"
                    v-model="paymentInfo.language"
                    name="language"
                />
                <input
                    type="hidden"
                    class="form-control"
                    v-model="paymentInfo.customer_email"
                    name="customer_email"
                />
                <input
                    type="hidden"
                    class="form-control"
                    v-model="signature"
                    name="signature"
                />
                <input
                    type="hidden"
                    class="form-control"
                    v-model="paymentInfo.order_description"
                    name="order_description"
                />
                <p v-if="isEnabled">Please Wait...</p>
                <p v-if="!isEnabled">
                    <button
                        type="submit"
                        class="btn btn-primary"
                        :disabled="isEnabled"
                    >
                        Pay Now
                    </button>
                </p>
            </form>
        </b-card>
    </Layout>
</template>
<script>
/* eslint-disable no-unused-vars */
import axios from "axios";
import { BASE_URL, PAYMENT_URL } from "../../common";
import Layout from "../../layouts/main.vue";

export default {
    components: {
        Layout,
    },

    data() {
        return {
            test: "200",
            refNumber: "",
            isEnabled: true,
            url: PAYMENT_URL,
            merchant_identifier: "",
            access_code: "",
            merchant_reference: "",
            signature: "",
        };
    },
    mounted() {
        // let data = localStorage.getItem("payFort");
        this.fetchSignature();
        // this.refNumber = localStorage.refNo;
    },
    methods: {
        fetchSignature() {
            this.test = this.paymentInfo.amount / 100;
            if (localStorage.cs_user_token) {
                axios
                    .post(
                        `${BASE_URL}getsignature?token=${localStorage.cs_user_token}`,
                        this.$store.getters["apidata/getPaymentData"]
                    )
                    .then((res) => {
                        this.test = this.paymentInfo.amount / 100;
                        console.log("testing", this.test);
                        this.merchant_identifier =
                            res.data.response.merchant_identifier;
                        this.access_code = res.data.response.access_code;
                        this.merchant_reference =
                            res.data.response.merchant_reference;
                        this.signature = res.data.response.signature;
                        let data = {
                            command: this.paymentInfo.command,
                            access_code: res.data.response.access_code,
                            merchant_identifier:
                                res.data.response.merchant_identifier,
                            merchant_reference:
                                res.data.response.merchant_reference,
                            amount: this.paymentInfo.amount,
                            currency: this.paymentInfo.currency,
                            language: this.paymentInfo.language,
                            customer_email: this.paymentInfo.customer_email,
                            signature: res.data.response.signature,
                            order_description:
                                this.paymentInfo.order_description,
                        };
                        this.isEnabled = false;
                        console.log("the data to amazon payfort", data);
                    });
            }
        },
    },
    computed: {
        paymentInfo() {
            return this.$store.getters["apidata/getPaymentData"];
        },
    },
};
</script>
<style scoped>
.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 350px;
    margin: auto;
    text-align: center;
}

.price {
    color: black;
    font-size: 22px;
}
.warning_text {
    font-size: 12px;
    font-style: italic;
}
</style>
